import "./style.scss";
import { useQuery } from "@apollo/client";
import { IonPage } from "@ionic/react";
import { Box } from "@mui/material";
import { ChatPage } from "@src/app/chat/chat";
import { ChatChannels } from "@src/app/chat/chatChannelsList";
import { LicenseManager } from "@src/app/licenseManager";
import { AddLicense } from "@src/app/licenseManager/addLicense";
import { useLicenseCount } from "@src/app/licenseManager/hooks/useLicenseCount";
import { LicenseDetails } from "@src/app/licenseManager/licenseDetails";
import { NeedHelpPage } from "@src/app/needhelp";
import HelpDetailsPage from "@src/app/needhelp/helpDetails";
import { SignNewAgreementPage } from "@src/app/onboardingStripe/components/signNewAgreement/Page";
import {
  DISMISSED_STRIPE_POPUP,
  IS_SIGNUP,
  WAS_APP_PAUSED,
} from "@src/app/onboardingStripe/constants";
import ProfessionalReferences from "@src/app/professionalReferences";
import { ContractPage } from "@src/app/profile/profile/contractPage";
import { DeleteData } from "@src/app/profile/profile/deleteData";
import { UpdateProfile } from "@src/app/profile/profile/updateProfile";
import { ReferAndEarnPage } from "@src/appV2/Accounts/ReferAndEarn";
import { ReferAndEarnRouterPath } from "@src/appV2/Accounts/ReferAndEarn/paths";
import { WorkerReferralsPage } from "@src/appV2/Accounts/WorkerReferrals";
import {
  WorkplaceReferralsPage,
  WorkplaceReferralsStatsPage,
} from "@src/appV2/Accounts/WorkplaceReferrals";
import { WorkplaceReferralRouterPath } from "@src/appV2/Accounts/WorkplaceReferrals/paths";
import { SIGN_NEW_AGREEMENT_PATH } from "@src/appV2/Agreements/paths";
import { RootPaths } from "@src/appV2/App/paths";
import { AppTabBar } from "@src/appV2/App/TabBar/AppTabBar";
import { StepUpAuthProvider } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/context";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { RouteTransitionSwitch } from "@src/appV2/lib";
import { ATeamPage } from "@src/appV2/Rankings/A-Team/Page";
import { CommentRepliesPage, ReviewsSummaryPage } from "@src/appV2/Reviews";
import { WorkplaceReviewsRouterPath } from "@src/appV2/Reviews/paths";
import { MyShiftsPage } from "@src/appV2/Shifts/MyShifts";
import { UnverifiedShiftsPage } from "@src/appV2/Shifts/UnverifiedShifts/Page";
import { ViewShiftPage } from "@src/ShiftView/Page";
import { VIEW_SHIFT_PATH } from "@src/ShiftView/paths";
import { difference, filter, isEmpty, keyBy } from "lodash";
import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { AccountPage } from "../account";
import { AddressEdit, AddressView, SearchLocationEdit } from "../address";
import { AppVersion } from "../appVersion";
import { WorkerAppVersion } from "../appVersion/workerAppVersion";
import { AttendanceScoreRatingPage } from "../attendancePolicy/attendanceScoreRatingPage";
import { ColleagueList } from "../components/workWithFriends/colleagueList";
import { WorkerFriendsList } from "../components/workWithFriends/friendsList";
import { OpenShiftDayViewPage } from "../dayView/Page";
import { DeprecatedHcfDocumentsPage } from "../documents";
import { GET_REQUIREMENTS_STATUS } from "../documents/gql";
import { FacilityDetailPage } from "../facilityDetail";
import { FacilityOpenShiftsList } from "../facilityOpenShifts/page";
import { MyShiftDetailsPage } from "../hcpShifts";
import { NfcFailurePage } from "../hcpShifts/components/nfcFailurePage";
import { NfcInstructionsPage } from "../hcpShifts/components/nfcInstructions";
import { NotificationCenter } from "../notificationCenter/notificationCenter";
import { NotificationsToast } from "../NotificationToast";
import { OnboardToStripe } from "../onboardingStripe";
import { OnboardingSuccess } from "../onboardingStripe/components/OnStripeSetUp";
import { SetUpStripe } from "../onboardingStripe/components/SetUpStripe";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { StripeSetupPage } from "../onboardingStripe/StripeSetupPage";
import { OngoingShiftSync } from "../ongoing/ongoingShiftSync";
import { OpenShifts } from "../openShifts";
import { UrgentShifts } from "../openShifts/urgentShifts";
import { PayrollPage } from "../payroll";
import { PayrollNewPage } from "../payrollNew";
import { InvalidPaymentAccountStatus } from "../payrollNew/models";
import { PendingSentHomeRequestList } from "../pendingSentHomeRequests/pendingSentHomeRequestsList";
import { PrivacyPolicyNotificationPage } from "../privacyPolicy";
import { NotificationPreferencePage } from "../privacyPolicy/notificationPreference";
import { PrivacyPolicyPage } from "../privacyPolicy/privacyPolicy";
import { ProfilePage } from "../profile";
import { PushNotificationComponent } from "../pushNotifications";
import { RateNegotiationsList } from "../rateNegotiation/negotiationsList";
import { PriorityAccessPage } from "../ratings/PriorityAccessPage";
import { RatingsPage } from "../ratings/RatingsPage";
import { ReferralPage } from "../referral";
import { CoWorkerReferralPage } from "../referral/pages/CoWorkerReferral";
import { AppV2AccountRoutes } from "../routing/constant/appV2AccountRoutes";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useDefinedAgent, useSession } from "../store/helperHooks";
import { Store } from "../store/store.model";
import { UnverifiedShiftsList } from "../unverifiedShifts/unverifiedShiftsList";

const NavigationPaths = new Set<string>([
  TabRouterPath.OPEN_SHIFTS,
  TabRouterPath.MY_SHIFTS,
  TabRouterPath.RATINGS,
  TabRouterPath.ACCOUNT,
]);

const ExcludedPages = Object.freeze([TabRouterPath.PROFILE, TabRouterPath.DOCUMENT_VIEW]);

export function AppTabs(): ReactElement {
  const match = useRouteMatch();

  const { pathname, search } = useLocation();

  const currentPathIsANavigationPath = NavigationPaths.has(pathname);
  const history = useHistory();
  const isUnverifiedShiftsV2Enabled = useCbhFlag(CbhFeatureFlag.UNVERIFIED_SHIFTS_V2_ENABLED, {
    defaultValue: false,
  });

  const agent = useDefinedAgent();
  const { paymentAccountInfo, license, address } = agent;

  const { userId: hcpId } = useSession();
  const unreadCount = useSelector((state: Store) => state.notificationStore.unreadCount);

  const { data } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
    fetchPolicy: "cache-and-network",
  });

  const { requirements, expired, missing, pending } = data?.hcpRequirementStatus || {};

  const requirementsById = keyBy(requirements, "reqId");

  const isVisibleToHCPAndNotHcfLevel = (reqId) => {
    const requirement = requirementsById[reqId];
    return requirement?.visibleToHCP && requirement?.level !== "HCF";
  };

  const pendingActions =
    filter(difference(missing, pending), isVisibleToHCPAndNotHcfLevel).length ||
    filter(difference(expired, pending), isVisibleToHCPAndNotHcfLevel).length;

  const shouldBeOnboarded = useShouldBeOnboarded();
  const { licensesNotificationCount } = useLicenseCount();
  const notificationsCount =
    (!agent || agent.isProfilePictureUploaded ? 0 : 1) +
    pendingActions +
    (isEmpty(address) ? 1 : 0) +
    (isEmpty(license) ? 1 : 0) +
    licensesNotificationCount +
    (unreadCount || 0) +
    (shouldBeOnboarded ? 1 : 0);

  const dismissedStripePopup = localStorage.getItem(DISMISSED_STRIPE_POPUP);
  const isSignup = localStorage.getItem(IS_SIGNUP);
  const wasAppPaused = localStorage.getItem(WAS_APP_PAUSED);
  const isJustCompletedStripeOnboarding = pathname === TabRouterPath.SUCCESS_ONBOARDING;

  // For business logic refer https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2457534599/Stripe+onboarding+popup
  useEffect(() => {
    if (shouldBeOnboarded) {
      if (dismissedStripePopup) {
        return;
      }

      if (ExcludedPages.includes(pathname)) {
        localStorage.removeItem(WAS_APP_PAUSED);
        return;
      }

      if (isSignup === "true") {
        localStorage.setItem(IS_SIGNUP, "false");
      } else if (isSignup === "false" && wasAppPaused) {
        localStorage.removeItem(WAS_APP_PAUSED);
        // We proceed to Stripe onboarding if we haven't complete it just now
        if (!isJustCompletedStripeOnboarding) {
          history.replace(TabRouterPath.PAYMENT_SERVICE_ONBOARDING);
        }
      }
    }
  }, [
    shouldBeOnboarded,
    history,
    wasAppPaused,
    isSignup,
    dismissedStripePopup,
    pathname,
    agent,
    isJustCompletedStripeOnboarding,
  ]);

  /**
   * FIXME - is this now a perma-flag? Should we always use true?
   */
  const showNewPayrollPage =
    paymentAccountInfo &&
    paymentAccountInfo.enabled &&
    !InvalidPaymentAccountStatus.includes(paymentAccountInfo.status);

  return (
    <div className="cbhAppV1">
      <Route path={`${match.url}`} component={WorkerAppVersion} />
      <Route path={`${match.url}`} component={OngoingShiftSync} />
      <Route path={`${match.url}`} component={PushNotificationComponent} />
      <Route path={`${match.url}`} component={NotificationsToast} />
      <Route path={`${match.url}`} component={AppVersion} />

      <Box
        sx={{
          /**
           * The Box here ensures that IonPage is positioned in a new z-index stack.
           * Remove this Box when we eliminate `IonPage`.
           * Test that scrolling to the bottom of the content still works, even
           * on a small UI.
           */
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Box position="relative" flex="1 1 0">
          <RouteTransitionSwitch>
            <Route path={TabRouterPath.MY_SHIFTS} render={() => <MyShiftsPage />} exact />
            <Route path={TabRouterPath.OPEN_SHIFTS} component={OpenShifts} exact />
            <Route path={TabRouterPath.URGENT_SHIFTS} component={UrgentShifts} exact />
            <Route path={TabRouterPath.RATINGS} component={RatingsPage} exact />
            <Route
              path={TabRouterPath.RATINGS_ATTENDANCE_SCORE}
              component={AttendanceScoreRatingPage}
              exact
            />
            <Route
              path={TabRouterPath.RATINGS_PRIORITY_ACCESS}
              component={PriorityAccessPage}
              exact
            />
            <Route path={TabRouterPath.RATINGS_A_TEAM} component={ATeamPage} exact />
            <Route
              path={TabRouterPath.OPEN_SHIFT_DAY_VIEW}
              component={OpenShiftDayViewPage}
              exact
            />
            <Route path={`${RootPaths.HOME}/${VIEW_SHIFT_PATH}`}>
              <ViewShiftPage />
            </Route>
            <Route
              path={TabRouterPath.OPEN_SHIFT_FACILITY}
              component={FacilityOpenShiftsList}
              exact
            />
            <Route path={TabRouterPath.ACCOUNT} component={AccountPage} exact />
            <Route path={TabRouterPath.CHAT} component={ChatPage} exact />
            <Route path={TabRouterPath.CHAT_CHANNELS_LIST} component={ChatChannels} exact />
            <Route path={TabRouterPath.ADDRESS} component={AddressView} exact />
            <Route path={TabRouterPath.ADDRESS_SEARCH} component={SearchLocationEdit} exact />
            <Route path={TabRouterPath.FACILITY_DETAIL} component={FacilityDetailPage} exact />
            <Route
              exact
              path={TabRouterPath.UPDATED_CONTRACTOR_AGREEMENT}
              render={() => <OnboardToStripe />}
            />
            <Route
              path={`${match.url}/account/searchAddressLocation`}
              component={SearchLocationEdit}
              exact
            />
            <Route path={TabRouterPath.ADDRESS_EDIT} component={AddressEdit} exact />
            <Route
              path={TabRouterPath.PAYROLL}
              exact
              render={() => (showNewPayrollPage ? <PayrollNewPage /> : <PayrollPage />)}
            />
            <Route path={TabRouterPath.NEED_HELP} component={NeedHelpPage} exact />
            <Route path={TabRouterPath.HELP_DETAILS} component={HelpDetailsPage} exact />
            <Route path={TabRouterPath.DEPRECATED_V1_DOCUMENTS_HCF} exact>
              <DeprecatedHcfDocumentsPage />
            </Route>
            <Redirect
              exact
              path={TabRouterPath.DEPRECATED_V1_DOCUMENTS}
              to={AppV2AccountRoutes.DOCUMENTS}
            />
            <Redirect
              exact
              path={TabRouterPath.DOCUMENT_VIEW}
              to={`${AppV2AccountRoutes.DOCUMENTS_DETAILS}${search}`}
            />
            <Route path={TabRouterPath.PROFILE} component={ProfilePage} exact />
            <Route path={TabRouterPath.PROFILE_CONTRACT} component={ContractPage} exact />
            <Route path={TabRouterPath.REFERRAL} component={ReferralPage} exact />
            <Route path={TabRouterPath.CO_WORKER_REFERRAL} component={CoWorkerReferralPage} exact />
            <Route path={TabRouterPath.REFERRAL_V2} exact>
              <IonPage>
                <WorkerReferralsPage />
              </IonPage>
            </Route>

            <Route path={TabRouterPath.MY_SHIFT_DAY} component={MyShiftsPage} exact />
            {/*Comes before MY_SHIFT_DETAIL, For more Info check CH-19509 on the differences between IonRouterOutlet and Switch*/}
            <Route
              path={TabRouterPath.UNVERIFIED_SHIFTS}
              component={isUnverifiedShiftsV2Enabled ? UnverifiedShiftsPage : UnverifiedShiftsList}
              exact
            />
            <Route
              path={TabRouterPath.PENDING_SENT_HOME_REQUESTS}
              component={PendingSentHomeRequestList}
              exact
            />
            <Route path={TabRouterPath.MY_SHIFT_DETAIL} component={MyShiftDetailsPage} exact />
            <Route path={TabRouterPath.SUCCESS_ONBOARDING} component={OnboardingSuccess} exact />
            <Route path={TabRouterPath.PAYMENT_SERVICE_ONBOARDING} component={SetUpStripe} exact />
            <Route path={TabRouterPath.FRIENDS_LIST} component={WorkerFriendsList} exact />
            <Route
              path={TabRouterPath.PROFESSIONAL_REFERENCE}
              component={ProfessionalReferences}
              exact
            />
            {/*--------- Privacy Policy and Notification Routes Starts --------*/}
            <Route
              path={`${match.url}/account/privacy-notification-setting`}
              component={PrivacyPolicyNotificationPage}
              exact
            />
            <Route
              path={`${match.url}/account/privacy-notification-setting/notification-preference`}
              component={NotificationPreferencePage}
              exact
            />
            <Route
              path={`${match.url}/account/privacy-notification-setting/privacy-policy`}
              component={PrivacyPolicyPage}
              exact
            />
            {/*--------- Privacy Policy and Notification Routes Ends --------*/}

            <Route path={TabRouterPath.NFC_INSTRUCTION} component={NfcInstructionsPage} exact />
            <Route path={TabRouterPath.NFC_FAILURE} component={NfcFailurePage} exact />
            <Route
              exact
              path={match.url}
              render={() => <Redirect to={TabRouterPath.OPEN_SHIFTS} />}
            />
            <Route exact path={TabRouterPath.UPDATE_PROFILE} component={UpdateProfile} />

            {/* License Manager Routes */}

            <Route path={TabRouterPath.LICENSE_MANAGER} component={LicenseManager} exact />
            <Route path={TabRouterPath.ADD_LICENSE} component={AddLicense} exact />
            <Route path={TabRouterPath.LICENSE_DETAILS} component={LicenseDetails} exact />

            <Route exact path={TabRouterPath.DELETE_DATA} component={DeleteData} />

            <Route path={TabRouterPath.SETUP_STRIPE} component={StripeSetupPage} exact />

            <Route path={TabRouterPath.COLLEAGUE_LIST} component={ColleagueList} exact />

            <Route path={TabRouterPath.RATE_NEGOTIATIONS} component={RateNegotiationsList} exact />

            <Route path={TabRouterPath.NOTIFICATION_CENTER} component={NotificationCenter} exact />

            {/**
             * Workplace Reviews Routes
             * FIXME: history.replace is not working as expecting because of bugs in IonRouterOutlet
             * history.replace does not function correctly when the route is in V2, so moving it here
             * https://www.loom.com/share/Clipboard-Health-15-September-2023-6e996f1559464c57acd2b7be64719719
             * Move this route to V2 once we move away from IonRouterOutlet
             */}
            <Route exact path={WorkplaceReviewsRouterPath.REVIEWS_SUMMARY_PATH}>
              <ReviewsSummaryPage />
            </Route>
            <Route exact path={WorkplaceReviewsRouterPath.REVIEW_COMMENT_REPLIES_PATH}>
              <CommentRepliesPage />
            </Route>

            {/**
             * FIXME: history.replace is not working as expecting because of bugs in IonRouterOutlet
             * history.replace does not function correctly when the route is in V2, so moving it here
             * Move this route to V2 once we move away from IonRouterOutlet
             */}
            <Route exact path={ReferAndEarnRouterPath.REFER_AND_EARN_PATH}>
              <ReferAndEarnPage />
            </Route>
            <Route exact path={WorkplaceReferralRouterPath.WORKPLACE_REFERRAL_PATH}>
              <WorkplaceReferralsPage />
            </Route>
            <Route exact path={WorkplaceReferralRouterPath.WORKPLACE_REFERRALS_STATS_PATH}>
              <WorkplaceReferralsStatsPage />
            </Route>
            <Route exact path={`${RootPaths.HOME}/${SIGN_NEW_AGREEMENT_PATH}`}>
              <IonPage>
                <StepUpAuthProvider>
                  <SignNewAgreementPage />
                </StepUpAuthProvider>
              </IonPage>
            </Route>
            {/* Fallback */}
            <Route render={() => <Redirect to={TabRouterPath.OPEN_SHIFTS} />} />
          </RouteTransitionSwitch>
        </Box>
        {currentPathIsANavigationPath && <AppTabBar notificationsCount={notificationsCount} />}
      </Box>
    </div>
  );
}
